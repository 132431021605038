.bn-logo-link {
  position: initial;
  margin: 0.4rem auto;
}

.bn-logo-demo {
  display: flex;
  width: 28rem;
}
/* 
.user-info-container {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #1B2A56;
  margin: 0.25rem;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  position: relative;
  padding: 4px;
}

.user-info {
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  border-radius: 4px;
}

.user-info span {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.25rem 1rem;
  position: relative;
  margin: 1rem;
  border: 1px solid black;
  border-radius: 12px;
  margin: 0.25rem 1rem;
}

.user-info .user-address {
  max-width: 92%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.user-avatar {
  width: 32px;
  border-radius: 100%;
  border: 1px solid black;
  left: 0;
  transform: translate(-50%, 0);
  position: absolute;
} */


@media screen and (max-width: 750px) {
  .user-info {
    width: 100%;
  }

  .bn-logo-demo {
    width: 24rem;
  }  
}
